<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
 
    <b-row
      v-if="order"
      class="invoice-preview"
    >
    

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <div class="mt-1">
            <b-row style="color: black">
              <b-col
                v-if="order.supplier"
                cols="4"
                md="4"
                sm="12"
                class="mx-1"
              >
                <!-- Header: Left Content -->
                <div>
                  <h4
                    class="mb-1 font-weight-bolder"
                  >
                    Fournisseur : {{ order.supplier.company_name }}
                  </h4>
                  <span class="mb-0 mt-1 font-weight-bold">
                    Adresse : {{ order.supplier.address }}
                  </span>
                  <b-card-text class="mb-0 mt-1 font-weight-bold">
                    MF : {{ order.supplier.tax_identification_number }}
                  </b-card-text>
                  <b-card-text class="mb-0 mt-1 font-weight-bold">
                    Date : {{ order.date }}
                  </b-card-text>
                  <b-card-text class="mb-0 mt-1 font-weight-bold">
                    Number : {{ order.number }}
                  </b-card-text>
                  <b-card-text class="mb-0 mt-1 font-weight-bold">
                    Currency : {{ order.currency }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- Invoice Description: Table -->
          <table class="container-products mt-2">
            <b-table-lite
              responsive
              :items="p"
              :small="true"
              :fields="[
                'N°',
                'Qté',
                'Label',
                `Prix U.${order.currency}`,
                `Total .${order.currency}`,
                'Prix U.DNT',
                'Total DNT',
              ]"
              class="ta font-weight-bold mt-1"
            >
              <template #cell(taskDescription)="data">
                <b-card-text
                  class="font-weight-bold mb-25"
                  style="font-size: 25px; border: 2px solid black !important"
                >
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </table>
        </b-card>
        <div>
            <b-row class="card-body">
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
                style="
                  border: 1px solid black;
                  padding: 10px,
                  border-radius: 18px;
                "
              >
                <span
                  class="font-weight-bolder mt-1"
                  style="
                    color: black;
                    border-bottom: 1px solid black;
                    font-size: 20px;
                  "
                >
                  Arreteé la présente facture a la somme de
                </span>
                <br><br>
                <span
                  class="font-weight-bold mt-1"
                  style="color: black; font-size: 20px"
                >
                  {{ lettre }}
                </span>
                <!-- <div v-html="order.note" /> -->
              </b-col>
              <b-col cols="1" />
              <b-col
                cols="5"
                md="5"
                xl="5"
                sm="12"
                class="p-0"
                style="
                  border: 1px solid black;
                  padding: 10px;
                  border-radius: 18px;
                "
              >
                <table class="border-pricing m-1">
                  <tbody>

                    <tr style="color: black; font-size: 20px">
                      <th
                        scope="row"
                        class="border-pricing"
                      >
                        Total prix en DT:
                      </th>
                      <td class="font-weight-bold border-pricing">
                        {{ (Number(order.price_ttc)).toFixed(3) }}
                      </td>
                    </tr>
                    <tr style="color: black; font-size: 20px">
                      <th
                        scope="row"
                        class="border-pricing"
                      >
                        Total prix en {{ order.currency }}:
                      </th>
                      <td class="font-weight-bold border-pricing">
                        {{ (Number(order.price_currency)).toFixed(3) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="card-body text-center">
              <b-col
                cols="6"
                md="6"
                xl="6"
                sm="12"
              >
                <h5 style="color: black; font-size: 16px">
                  Signature
                </h5>
              </b-col>
            </b-row>
          </div>

      </b-col>
    </b-row>
  </section>
</template>

<script>
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      p: [],
      order: [],
      lettre: '',
      id: null,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getPurchaseOrder()
  },
  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    async getPurchaseOrder() {
      const { data } = await axiosIns.get(`/purchase-orders/details/${this.id}/`)
      this.order = data
      const sommeString = this.order.price_ttc.toString()
      if (sommeString.includes('.000') === true) {
        this.lettre = `${NumberToLetter(this.order.price_ttc)} Dinars `
      } else {
        const [dinar, milim] = this.order.price_ttc.split('.')
        this.lettre = `${NumberToLetter(dinar)} Dinars , ${NumberToLetter(
          milim,
        )} Millimes`
      }
      this.getProducts()
    },

    async getProducts() {
      const { data } = await axiosIns.post('/purchase-orders-products/details/',
        {
          order: Number(this.id),
        })
      for (let i = 0; i < data.length; i += 1) {
        this.addnewitem(
          i + 1,
          data[i].quantity,
          data[i].label,
          data[i].purchase_price_ttc,
          data[i].total_purchase_ttc,
          data[i].price_currency,
          data[i].total_price_currency,

        )
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      quantity,
      label,
      purchase_price_ttc,
      total_purchase_ttc,
      price_ttc,
      total_ttc,

    ) {
      this.p.push({
        Qté: quantity,
        Label: label,
        [`Prix U.${this.order.currency}`]: price_ttc,
        [`Total .${this.order.currency}`]: total_ttc,
        'Prix U.DNT': purchase_price_ttc,
        'Total DNT': total_purchase_ttc,
        'N°': nb,
      })
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },
}
</script>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
  @page {
    size: A4;
  }

  .ta {
    overflow-x: visible;
    font-size: 18px;
    color: black;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  * {
    color: black !important;
  }

  .border-pricing {
    text-align: left;
    border: 0px solid black;
  }

}
@import "~@core/scss/base/pages/app-invoice.scss";
.trborder {
  border-bottom: 0px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
.container-products{
  min-height: 500px;
  border: 1px solid black;
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}
</style>
